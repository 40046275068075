// From http://lealog.hateblo.jp/entry/2015/02/24/131643
// This shims iOS chrome autofill to stop it from failing

const noop = function () {};

window.__gCrWeb = window.__gCrWeb || {};
window.__gCrWeb.autofill = window.__gCrWeb.autofill || {};
window.__gCrWeb.autofill.extractForms = window.__gCrWeb.autofill.extractForms || noop;
window.__gCrWeb.innerSizeAsString = window.__gCrWeb.innerSizeAsString || noop;
window.__gCrWeb.getElementFromPoint = window.__gCrWeb.getElementFromPoint || noop;
window.__gCrWeb.hasPasswordField = window.__gCrWeb.hasPasswordField || noop;

window.__gCrWeb.suggestion = window.__gCrWeb.suggestion || {};
window.__gCrWeb.suggestion.hasPreviousElement = window.__gCrWeb.suggestion.hasPreviousElement || noop;
window.__gCrWeb.suggestion.hasNextElement = window.__gCrWeb.suggestion.hasNextElement || noop;

window.__gCrWeb.stringify = window.__gCrWeb.stringify || noop;
window.__gCrWeb.getPageWidth = window.__gCrWeb.getPageWidth || noop;

window.__gCrWeb.message = window.__gCrWeb.message || {};
window.__gCrWeb.message.invokeOnHost = window.__gCrWeb.message.invokeOnHost || noop;
window.__gCrWeb.message.invokeQueues = window.__gCrWeb.message.invokeQueues || noop;

window.inf = typeof window.inf !== "undefined" ? window.inf : {};
window.url = typeof window.url !== "undefined" ? window.url : {};
window.target = typeof window.target !== "undefined" ? window.target : {};
