import React from "react";
import PropTypes from "prop-types";

import {
  Form,
  Input,
  PrimaryButton,
  Heading2
} from "@nulogy/components";

const ConfirmUserPage= ({
  authenticityToken,
  resourceName,
  updateUserConfirmationPath,
  confirmationToken
}) => (
  <>
    <Heading2>Create Password</Heading2>

    <Form style={{ width: "100%" }} action={ updateUserConfirmationPath } method='post' acceptCharset='UTF-8'>
      <Input
        type='password'
        labelText='New password'
        name={ `${resourceName}[password]` }
        autoFocus
      />
      <Input
        type='password'
        labelText='Confirm new password'
        name={ `${resourceName}[password_confirmation]` }
      />
      <Input type='hidden' name='confirmation_token' value={ confirmationToken }/>
      <Input type='hidden' name='authenticity_token' value={ authenticityToken }/>
      <Input type='hidden' name='_method' value='put' />
      <PrimaryButton fullWidth>Sign In</PrimaryButton>
    </Form>
  </>
);

ConfirmUserPage.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired,
  updateUserConfirmationPath: PropTypes.string.isRequired,
  confirmationToken: PropTypes.string
};

export default ConfirmUserPage;
