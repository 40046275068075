import React from "react";
import PropTypes from "prop-types";

import {
  Form,
  Input,
  PrimaryButton,
  Heading2
} from "@nulogy/components";

const ResendConfirmationPage= ({
  authenticityToken,
  resourceName,
  confirmationPath
}) => (
  <>
    <Heading2 style={ { width: "100%", textAlign: "center" } }>Resend email confirmation</Heading2>

    <Form style={{ width: "100%" }} action={ confirmationPath } method='post' acceptCharset='UTF-8'>
      <Input
        labelText='Email'
        helpText="We'll send a confirmation link to your registered email."
        name={ `${resourceName}[email]` }
        autoFocus
      />
      <Input type='hidden' name='authenticity_token' value={ authenticityToken }/>
      <PrimaryButton fullWidth>Resend Email</PrimaryButton>
    </Form>
  </>
);

ResendConfirmationPage.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired,
  confirmationPath: PropTypes.string.isRequired
};

export default ResendConfirmationPage;
