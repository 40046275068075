import React from "react";

import "@babel/polyfill";
import "./shim/ios-chrome-autofill";
import ReactDOM from "react-dom";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import { NDSProvider } from "@nulogy/components";
import AuthLayout from "./Auth/AuthLayout";
import SignInPage from "./Auth/SignInPage";
import ResetPasswordEmailPage from "./Auth/ResetPasswordEmailPage";
import ResetPasswordPage from "./Auth/ResetPasswordPage";
import ResendConfirmationPage from "./Auth/ResendConfirmationPage";
import PasswordExpiredPage from "./Auth/PasswordExpiredPage";
import ConfirmUserPage from "./Auth/ConfirmUserPage";
import { authData } from "./app/support/static-data-deserializers";
import { csrfToken } from "./lib/HttpUtils";

import NotFoundRouteHandler from "./app/route-handlers/NotFoundRouteHandler";

/* eslint-enable */

const authProps = () => ({
  ...authData(document),
  authenticityToken: csrfToken()
});

const changePasswordProps = props => ({
  resetPasswordToken: (new URLSearchParams(props.location.search)).get("reset_password_token")
});

const confirmUserProps = props => ({
  confirmationToken: (new URLSearchParams(props.location.search)).get("confirmation_token")
});

const AuthApp = () => (
  <NDSProvider>
    <AuthLayout {...authProps()}>
      <Switch>
        <Route path='/users/sign_in' render={() => <SignInPage {...authProps()} />} />
        <Route path='/users/password/new' render={() => <ResetPasswordEmailPage {...authProps()} />} />
        <Route path='/users/password/edit' render={props => <ResetPasswordPage {...changePasswordProps(props)} {...authProps()} />} />
        <Route path='/users/password_expired' render={props => <PasswordExpiredPage {...changePasswordProps(props)} {...authProps()} />} />
        <Route path='/users/confirmation/new' render={() => <ResendConfirmationPage {...authProps()} />} />
        <Route path='/users/confirmation' render={props => <ConfirmUserPage {...confirmUserProps(props)} {...authProps()} />} />
        <Route component={NotFoundRouteHandler} />
      </Switch>
    </AuthLayout>
  </NDSProvider>
);

const history = createBrowserHistory();

ReactDOM.render(
  <Router history={history}>
    <Route path='/' component={AuthApp} />
  </Router>,
  document.getElementById("app")
);
