import React from "react";
import PropTypes from "prop-types";

import {
  Form,
  Input,
  PrimaryButton,
  Box,
  Link,
  Heading2
} from "@nulogy/components";

const ResetPasswordEmailPage= ({
  authenticityToken,
  resourceName,
  signInPath,
  passwordPath
}) => (
  <>
    <Heading2 style={ { width: "100%", textAlign: "center" } }>Forgot your password?</Heading2>

    <Form style={{ width: "100%" }} action={ passwordPath } method='post' acceptCharset='UTF-8'>
      <Input
        labelText='Email'
        helpText="We'll send a reset link to your registered email."
        name={ `${resourceName}[email]` }
        autoFocus
      />
      <Input type='hidden' name='authenticity_token' value={ authenticityToken }/>
      <PrimaryButton fullWidth>Send Password Reset Link</PrimaryButton>
    </Form>
    <Box py='x1' mt='x1'>
      <Link href={ signInPath }>Cancel</Link>
    </Box>
  </>
);

ResetPasswordEmailPage.propTypes = {
  authenticityToken: PropTypes.string,
  resourceName: PropTypes.string,
  signInPath: PropTypes.string,
  passwordPath: PropTypes.string.isRequired
};

export default ResetPasswordEmailPage;
