import request from "superagent";
import reduce from "lodash/reduce";
import assign from "lodash/assign";

import Response from "./../app/support/Response";

export default {
  onUnauthorizedRequest(err) {
    throw err;
  },

  async get(url, params) {
    const queryString = reduce(params, (result, value, key) => {
      if (value !== undefined && value !== null) {
        result.push(`${key}=${encodeURIComponent(value)}`);
      }

      return result;
    }, []).join("&");

    return await buildRequest("get", `${url}?${queryString}`, null, this.onUnauthorizedRequest);
  },

  async post(url, data) {
    return await buildRequest("post", url, data, this.onUnauthorizedRequest);
  },

  async postFile(url, data) {
    return await buildRequest("post", url, data, this.onUnauthorizedRequest, true);
  },

  async destroy(url) {
    return await buildRequest("del", url, null, this.onUnauthorizedRequest);
  },

  async put(url, data) {
    return await buildRequest("put", url, data, this.onUnauthorizedRequest);
  }
};

export const buildRequest = async (method, url, data, onUnauthorizedRequest, isFile) => {
  const req = request[method](url)
    .set("Accept", "application/json")
    .set("X-Requested-With", "XMLHttpRequest")
    .set("X-CSRF-Token", csrfToken());

  if (!isFile && method !== "del") {
    req.set("Content-Type", "application/json");
  }

  if (isFile) {
    req.attach("file", data.data);
  } else if (data) {
    req.send(JSON.stringify(data));
  }

  return new Promise((resolve, reject) => {
    req.end((err, res) => {
      handleResponse(resolve, reject, err, res, onUnauthorizedRequest);
    });
  }).catch(e => {
    setTimeout(() => {
      throw e;
    }, 0);
  });
};

export const wasSuccessful = response => {
  return response.statusCode === 200;
};

export const csrfToken = (() => {
  let token;

  return () => {
    if (!token) {
      token = document.body.getAttribute("csrf-token");
    }
    return token;
  };
})();

const DEFAULT_CLIENT_ERRORS = { errors: { base: ["Client Error"] } };

export const handleResponse = (resolve, reject, err, res, onUnauthorizedRequest) => {
  if (!err) {
    resolve(new Response(assign(res.body, { statusCode: res.status })));
  } else {
    err = err || {};
    if (err.status === 401) {
      onUnauthorizedRequest(err);
    } else if (err.status === 302) {
      const redirect_url = res.headers["x-ajax-redirect-url"];
      if (redirect_url !== undefined) {
        window.location.pathname = redirect_url;
      }
    } else {
      const response = err.response || {};
      resolve(new Response(assign({}, DEFAULT_CLIENT_ERRORS, response.body, { statusCode: err.status })));
    }
  }
};

