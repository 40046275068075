import React from "react";
import PropTypes from "prop-types";

import {
  Form,
  Input,
  PrimaryButton,
  Box,
  Link,
  Heading2
} from "@nulogy/components";

const ResetPasswordPage= ({
  authenticityToken,
  resourceName,
  signInPath,
  passwordPath,
  resetPasswordToken
}) => (
  <>
    <Heading2>Reset Password</Heading2>

    <Form style={{ width: "100%" }} action={ passwordPath } method='post' acceptCharset='UTF-8'>
      <Input
        type='password'
        labelText='New password'
        name={ `${resourceName}[password]` }
        autoFocus
      />
      <Input
        type='password'
        labelText='Confirm new password'
        name={ `${resourceName}[password_confirmation]` }
      />
      <Input type='hidden' name={ `${resourceName}[reset_password_token]` } value={ resetPasswordToken }/>
      <Input type='hidden' name='authenticity_token' value={ authenticityToken }/>
      <Input type='hidden' name='_method' value='put' />
      <PrimaryButton fullWidth>Reset Password</PrimaryButton>
    </Form>
    <Box py='x1' mt='x1'>
      <Link href={ signInPath }>Cancel</Link>
    </Box>
  </>
);

ResetPasswordPage.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired,
  signInPath: PropTypes.string.isRequired,
  passwordPath: PropTypes.string.isRequired,
  resetPasswordToken: PropTypes.string
};

export default ResetPasswordPage;
