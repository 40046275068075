import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  Button,
  Flex,
  IconicButton,
  Input,
  Link,
  LoadingAnimation,
  PrimaryButton
} from "@nulogy/components";


const SignInPage = ({
  authenticityToken,
  resourceName,
  signInPath,
  newPasswordPath
}) => {
  const [moreSignInOptionsShown, setMoreSignInOptionsShown] = React.useState(false);
  const [signInPressed, setSignInPressed] = useState(false);

  return (
    <>
      <form style={{ width: "100%" }} action={signInPath} method="post" acceptCharset="UTF-8">
        <Flex gap="x3" flexDirection="column">
          <Input labelText="Email" className="spec-sign-in-email" name={`${resourceName}[email]`} autoFocus/>
          <Flex gap="x1" flexDirection="column">
            <Input type="password" labelText="Password" name={`${resourceName}[password]`}/>
            <Link href={newPasswordPath} underline={false}>Forgot your password?</Link>
          </Flex>
          <Input type="hidden" name="authenticity_token" value={authenticityToken}/>
          <PrimaryButton fullWidth>Sign in</PrimaryButton>
        </Flex>
      </form>

      <Flex gap="x1" mt="x2" alignItems="center" flexDirection="column" width="100%">
        <IconicButton
          data-testid="sign-in-options-button"
          fullWidth
          onClick={() =>
            setMoreSignInOptionsShown(!moreSignInOptionsShown)
          }
          icon={moreSignInOptionsShown ? "downArrow" : "rightArrow"}
        >
          {moreSignInOptionsShown
            ? "Less sign in options"
            : "More sign in options"}
        </IconicButton>

        {moreSignInOptionsShown && (
          <form style={{ width: "100%" }} action="/users/auth/auth0" method="post" acceptCharset="UTF-8"
            onSubmit={() => setSignInPressed(true)}>
            <Flex alignItems="center" flexDirection="column">
              <input type="hidden" name="authenticity_token" value={authenticityToken}/>
              {!signInPressed && <Button fullWidth>Sign in with SSO</Button>}
              {signInPressed && <LoadingAnimation/>}
            </Flex>
          </form>
        )}
      </Flex>
    </>
  );
}
;

SignInPage.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired,
  signInPath: PropTypes.string.isRequired,
  newPasswordPath: PropTypes.string.isRequired
};

export default SignInPage;
