import flowRight from "lodash/flowRight";
import unescape from "lodash/unescape";


const unescapeJSON = flowRight(JSON.parse, unescape);


function accountData(doc) {
  return getJsonDataFromNode(doc, "account-data");
}

function authData(doc) {
  return getJsonDataFromNode(doc, "auth-data");
}

function environmentData(doc) {
  return getJsonDataFromNode(doc, "environment-data");
}

function featureFlagData(doc) {
  return getJsonDataFromNode(doc, "feature-flag-data");
}

function flashData(doc) {
  return getJsonDataFromNode(doc, "flash-data");
}

function userData(doc) {
  return getJsonDataFromNode(doc, "user-data");
}


function getJsonDataFromNode(doc, nodeId) {
  const escapedData = doc.getElementById(nodeId).innerHTML;
  return unescapeJSON(escapedData);
}

export {
  accountData,
  authData,
  environmentData,
  featureFlagData,
  flashData,
  userData
};
