import React, { Component } from "react";
import { observer } from "mobx-react";

@observer
export default class NotFoundRouteHandler extends Component {
  render() {
    return (
      <div>
        <h1>Not Found</h1>
        <p>The requested page could not be found</p>
        <a href='/'>Go Back</a>
      </div>
    );
  }
}
