import React from "react";
import PropTypes from "prop-types";

import {
  Form,
  Input,
  PrimaryButton,
  Box,
  Link,
  Heading2
} from "@nulogy/components";

const PasswordExpiredPage= ({
  authenticityToken,
  resourceName,
  destroyUserSessionPath,
  passwordExpiredPath
}) => (
  <>
    <Heading2>Reset your password</Heading2>

    <Form style={{ width: "100%" }} action={ passwordExpiredPath } method='post' acceptCharset='UTF-8'>
      <Input
        type='password'
        labelText='Current password'
        name={ `${resourceName}[current_password]` }
        autoFocus
      />
      <Input
        type='password'
        labelText='New password'
        name={ `${resourceName}[password]` }
      />
      <Input
        type='password'
        labelText='Confirm new password'
        name={ `${resourceName}[password_confirmation]` }
      />
      <Input type='hidden' name='authenticity_token' value={ authenticityToken }/>
      <Input type='hidden' name='_method' value='put' />
      <PrimaryButton fullWidth>Reset Password</PrimaryButton>
    </Form>
    <Box py='x1' mt='x1'>
      <Link href={ destroyUserSessionPath }>Cancel</Link>
    </Box>
  </>
);

PasswordExpiredPage.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired,
  destroyUserSessionPath: PropTypes.string.isRequired,
  passwordExpiredPath: PropTypes.string.isRequired
};

export default PasswordExpiredPage;
