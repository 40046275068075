import isEmpty from "lodash/isEmpty";
import assign from "lodash/assign";

export default class Response {
  constructor(payload = {}) {
    payload = payload || {}; // default if payload is null
    const { errors = {}, statusCode = 200, ...rest } = payload;
    this._errors = errors;
    this._statusCode = statusCode;
    assign(this, rest);
  }

  get errors() {
    return isEmpty(this._errors) ? null : this._errors;
  }

  get statusCode() {
    return this._statusCode;
  }

  addError(fieldName, message) {
    this._errors[fieldName] = this._errors[fieldName] || [];
    this._errors[fieldName].push(message);
  }

  setErrors(fieldName, errors) {
    this._errors[fieldName] = errors;
  }
}
